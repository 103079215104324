<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    overlay-opacity="0.95"
    persistent
    content-class="rounded-xl"
    style="overflow: hidden"
  >
    <v-card v-if="!!ticket" style="overflow: hidden" rounded="xl">
      <v-card-title class="headline">
        <div class="w-full text-center">
          <p
            class="text-overline font-weight-bold mb-0 text--secondary text-truncate"
            style="line-height: 1em"
          >
            {{ ticket.TicketBlock.TicketGroup.name }}
            •
            {{ ticket.TicketBlock.name }}
          </p>
          <p class="text-body-1 font-weight-black text-truncate mb-0">
            <span class="">
              {{ ticket.TicketBlock.TicketGroup.Party.name }}
            </span>
          </p>
          <p
            v-if="ticket.Table"
            class="text-overline font-weight-bold mb-0 text--secondary text-truncate mt-1"
            style="line-height: 1em"
          >
            {{ ticket.Table.Group.name }}
            •
            {{ ticket.Table.name }}
          </p>
        </div>
      </v-card-title>
      <v-card-text class="">
        <div class="d-flex justify-center">
          <div class="rounded" style="position: relative">
            <qrcode-vue
              style="
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
                background-size: cover;
              "
              class="d-flex justify-center pa-5 ma-1 white rounded"
              :value="qrCodeLink"
              size="260"
              level="M"
            />
            <div
              style="position: absolute; top: 0px; height: 100%; width: 100%"
              class="d-flex justify-center align-center"
            >
              <img
                style="
                  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
                  background-size: cover;
                "
                class="rounded-lg"
                src="@/assets/images/logo/logo-short-dark.png"
                contain
                width="92"
              />
            </div>

            <v-fade-transition>
              <v-overlay
                v-if="ticketUsed"
                absolute
                opacity="0.9"
                color="black"
              >
                <div class="d-flex flex-column justify-center align-center">
                  <v-icon class="white--text" size="100">mdi-ticket</v-icon>
                  <h3 class="white--text text-h5 font-weight-black text-center">
                    Ingresso já utilizado
                  </h3>
                  <small>
                    em
                    {{
                      ticket.TicketEntry[0].createdAt | date("DD/MM/YY HH:mm")
                    }}
                  </small>
                </div>
              </v-overlay>
            </v-fade-transition>
          </div>
        </div>

        <v-alert color="info" class="mb-0 mt-2" dense border="bottom">
          O ingresso pertence a
          <span class="font-weight-bold">{{ user.name }}</span> com CPF
          <span class="font-weight-bold">{{ obfuscatedCPF }}</span>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close" block>Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { mapGetters } from "vuex";
export default {
  components: { QrcodeVue },
  data: () => ({
    dialog: false,
    ticket: null,
  }),
  methods: {
    open(ticket, party) {
      this.ticket = ticket;
      this.party = party;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.ticket = null;
      this.party = null;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ticketUsed() {
      if (!this.ticket) return false;
      const periodsLength = this.party?.Period?.length || 1;
      return (
        this.ticket.TicketEntry &&
        this.ticket.TicketEntry.length >= periodsLength
      );
    },
    obfuscatedCPF() {
      return this.user.document
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.***.***-$4");
    },
    qrCodeLink() {
      return `${window.location.origin}/verify/ticket/${this.ticket.code}`;
    },
  },

  mounted() {
    this.$parent.$on("openQrCode", this.open);
  },
};
</script>

<style></style>
