<template>
  <v-card
    class="rounded-xl"
    :elevation="status.isInProgress ? '10' : status.isActive ? '5' : '3'"
  >
    <div>
      <v-img
        :src="party.cover"
        class="rounded-xl rounded-b-0"
        :aspect-ratio="16 / 9"
        :style="{
          filter: !status.isActive ? `grayscale(80%)` : undefined,
          cursor: 'pointer',
        }"
        @click="coverClick"
        v-ripple
      />
      <div
        class="d-flex flex-column align-start"
        style="position: absolute; top: 10px; left: 10px; gap: 0.5rem"
      >
        <v-chip v-if="status.isInProgress" label small color="warning">
          <v-icon x-small left>mdi-clock-outline</v-icon>
          Em andamento
        </v-chip>
        <v-chip v-if="!status.isActive" label x-small color="secondary">
          <v-icon x-small left>mdi-clock-outline</v-icon>
          Encerrado
        </v-chip>
        <v-chip v-if="party.private" color="info" label x-small>
          <v-icon x-small left>mdi-account-lock</v-icon>
          Privado
        </v-chip>
      </div>
    </div>
    <v-card-title
      class="font-weight-bold d-block mb-1 text-18"
      style="word-break: normal; line-height: 1.25rem"
    >
      <v-chip
        x-small
        label
        :color="ticketStatusColor[ticketStatus.status]"
        class="float-right ml-2"
      >
        <template v-if="ticketStatus.status !== 'invalid'">
          <v-icon x-small left>
            mdi-{{ ticketStatus.status == "valid" ? "check" : "alert" }}
          </v-icon>
          {{ ticketStatus.length }} ingresso{{
            ticketStatus.length != 1 ? "s" : ""
          }}
          <template v-if="ticketStatus.status == 'pending'">
            pendente{{ ticketStatus.length != 1 ? "s" : "" }}
          </template>
        </template>
        <template v-else>
          <v-icon x-small left>mdi-close</v-icon>
          Nenhum ingresso válido
        </template>
      </v-chip>
      {{ party.name }}
    </v-card-title>
    <v-card-subtitle
      class="d-flex justify-space-between align-center w-full pb-2"
    >
      <span>
        {{
          party.date
            | date(
              status.isActive ? "DD [de] MMM HH:mm" : "DD [de] MMM [de] YYYY"
            )
        }}
      </span>
      <span @click="goToOrg" style="cursor: pointer" class="text-end">
        {{ party.Organization.name }}
      </span>
    </v-card-subtitle>

    <v-card-text class="pb-0">
      <v-card outlined class="d-flex flex-column pa-3 mb-0 gap-1 rounded-lg">
        <b v-if="validTickets.length || invalidTickets.length">
          {{
            ticketStatus.status === "valid"
              ? validTickets.length != 1
                ? "Seus Ingressos"
                : "Seu Ingresso"
              : invalidTickets.length != 1
              ? "Seus Ingressos"
              : "Seu Ingresso"
          }}
        </b>
        <span v-else> Você não possui ingressos para este evento </span>

        <v-alert
          v-for="(ticket, i) of [...validTickets, ...invalidTickets]"
          :key="i"
          :border="ticket.TicketEntry ? undefined : 'left'"
          text
          :color="ticketStatusLabel[ticket.Payment.status].color"
          style="cursor: pointer"
          v-ripple
          class="mb-0 pr-1"
          @click="goToTicket(ticket)"
          dense
          contentClass="pr-0"
        >
          <div class="d-flex align-center gap-1">
            <b v-if="[...validTickets, ...invalidTickets].length > 1"
              >{{ i + 1 }}.</b
            >
            <b class="mb-0 text-14 font-weight-medium">
              {{ ticket.TicketBlock.TicketGroup.name }} •
              {{ ticket.TicketBlock.name }}
            </b>
            <v-chip v-if="ticket.TicketEntry" label x-small class="ml-1">
              Usado
            </v-chip>
            <v-spacer />

            <v-chip
              class="flex-shrink-0"
              label
              x-small
              :color="ticketStatusLabel[ticket.Payment.status].color"
            >
              <v-icon x-small left>
                {{ ticketStatusLabel[ticket.Payment.status].icon }}
              </v-icon>
              {{ ticketStatusLabel[ticket.Payment.status].text }}
            </v-chip>
            <v-icon color="grey">mdi-chevron-right</v-icon>
          </div>
        </v-alert>
      </v-card>
    </v-card-text>

    <div class="d-flex flex-column px-4 pb-4" style="gap: 0.25rem">
      <!-- <v-btn small block text @click="goToParty"> Ver detalhes </v-btn> -->
      <v-btn
        v-if="status.isActive && ticketStatus.status === 'invalid'"
        block
        @click="buyTickets"
        color="primary"
        class="mt-2"
      >
        Comprar ingresso
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import PartyStatus from "@/utils/partyStatus";
import Payment from "@/utils/payment";

export default {
  data() {
    return {
      ticketStatusLabel: Payment.ticketStatus,
      ticketStatusColor: {
        valid: "success",
        invalid: "error",
        pending: "warning",
      },
    };
  },
  methods: {
    coverClick() {
      if (this.ticketStatus.status === "invalid") return this.buyTickets();
      if (this.ticketStatus.status === "valid")
        return this.goToTicket(this.validTickets[0]);
      this.buyTickets();
    },
    goToOrg() {
      this.$router.push({
        name: "shop.organization",
        params: {
          orgSlug: this.party.Organization.slug,
        },
      });
    },
    buyTickets() {
      this.$router.push({
        name: "shop.party",
        params: {
          orgSlug: this.party.Organization.slug,
          partyId: this.party.id,
        },
      });
    },
    goToTicket({ id }) {
      this.$router.push({
        name: "app.ticket.details",
        params: { id: id },
      });
    },
  },
  computed: {
    status() {
      return new PartyStatus(this.party);
    },
    validTickets() {
      const pendingPaymentIds = [];
      return this.party.tickets.filter((ticket) => {
        if (ticket.Payment.status === "pending") {
          if (pendingPaymentIds.includes(ticket.Payment.id)) return false;
          pendingPaymentIds.push(ticket.Payment.id);
        }

        return !["canceled", "refunded", "disputed"].includes(
          ticket.Payment.status
        );
      });
    },
    invalidTickets() {
      return this.party.tickets
        .filter((ticket) => {
          return ["refunded", "disputed"].includes(ticket.Payment.status);
        })
        .sort((a, b) => {
          return new Date(b.Payment.updatedAt) - new Date(a.Payment.updatedAt);
        });
    },
    ticketStatus() {
      const hasActive = this.validTickets;

      if (hasActive.length) {
        const hasPending = hasActive.filter((ticket) => {
          return ticket.Payment.status === "pending";
        });

        if (hasPending.length)
          return { status: "pending", length: hasPending.length };

        return { status: "valid", length: hasActive.length };
      }
      const hasCanceled = this.invalidTickets;

      return { status: "invalid", length: hasCanceled.length };
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
