<template>
  <div class="pt-4">
    <h4 :class="{ 'white--text': $vuetify.breakpoint.smAndDown }">
      Meus Eventos
    </h4>
    <div class="px-2" v-if="loading">
      <v-progress-linear indeterminate />
    </div>
    <v-alert
      v-if="responseType === 'cache'"
      type="info"
      icon="mdi-web-off"
      prominent
    >
      <b>Exibindo Ingressos offline.</b> Quando a conexão com a internet for
      restabelecida, seus ingressos serão atualizados automaticamente.
    </v-alert>
    <cols-organizer
      v-if="parties.length"
      :items="parties"
      :cols="1"
      :md="2"
      :lg="3"
      :xl="3"
    >
      <template v-slot:default="{ item }">
        <my-party :party="item" />
      </template>
    </cols-organizer>
    <div
      v-else-if="!loading"
      style="height: 50vh"
      class="d-flex flex-column justify-center"
    >
      <div class="justify-center d-flex">
        <v-avatar size="75" color="grey lighten-3">
          <v-icon x-large color="grey darken-2">mdi-ticket</v-icon>
        </v-avatar>
      </div>
      <p class="text-center text-subtitle-1 mb-6 mt-1">
        Você não possui ingressos
      </p>
    </div>

    <v-alert
      v-if="error"
      border="left"
      class="text-left"
      text
      icon="mdi-alert-circle-outline"
      type="error"
    >
      {{ error }}
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ColsOrganizer from "../../../components/global/ColsOrganizer.vue";
import MyParty from "../../../components/app/myTickets/MyParty.vue";
import PartySort from "@/utils/partySortFunction";

export default {
  metaInfo: { title: "Meus ingressos" },
  components: { ColsOrganizer, MyParty },
  data: () => ({
    loading: false,
    error: false,
    responseType: "online",
  }),
  watch: {
    isOnline(value) {
      if (value) this.getTicketsController();
    },
  },
  methods: {
    ...mapActions("app", ["updateTickets"]),
    async getTicketsController() {
      try {
        this.loading = true;
        await this.updateTickets();
        this.responseType = "online";
      } catch (error) {
        this.responseType = "cache";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("app", ["tickets"]),
    parties() {
      return this.tickets
        .reduce((acc, ticket) => {
          const party = ticket.TicketBlock.TicketGroup.Party;
          const partyIndex = acc.findIndex((p) => p.id === party.id);
          if (partyIndex == -1) {
            acc.push({ ...party, tickets: [ticket] });
          } else {
            acc[partyIndex].tickets.push(ticket);
          }

          return acc;
        }, [])
        .sort(PartySort);
    },
  },
  created() {
    this.getTicketsController();
  },
};
</script>

<style></style>
