<template>
  <v-dialog
    v-model="dialog"
    max-width=" 400px"
    overlay-opacity="0.9"
    :persistent="!error"
  >
    <v-card :loading="loading">
      <v-card-text class="pt-6">
        <div v-if="payment">
          <!-- Text redirect to payment -->
          <v-img
            src="@/assets/images/brands/mercadopago.svg"
            class="mb-6"
            height="100"
            contain
          />
          <p class="text--primary text-center mb-0">
            Conclua seu pagamento com segurança via Mercado Pago.
          </p>
          <p class="text--primary text-center">
            Após o pagamento, você será redirecionado para o seu ingresso.
          </p>
          <!-- progressbar -->
          <v-progress-linear
            :value="(progressBar.time / progressBar.initial) * 100"
          />
        </div>
        <v-alert
          v-if="error"
          border="left"
          class="text-left mt-2 mb-2"
          text
          icon="mdi-alert-circle-outline"
          type="error"
        >
          {{ error }}
        </v-alert>
        <!-- <div class="d-flex justify-space-between mt-2" v-if="!!payment">
            <div>
              <h6 class="text--primary text--subtitle-2 mb-0">
                {{
                  ((payment.amount - payment.applicationFeeAmount) / 100)
                    | currency
                }}
              </h6>
              <span class="text--caption">
                +{{ (payment.applicationFeeAmount / 100) | currency }} taxa
              </span>
            </div>
          </div> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TICKET from "@/services/app/ticket";

export default {
  data: () => ({
    dialog: false,
    loading: false,
    error: false,
    payment: null,
    progressBar: {
      initial: 0,
      time: 0,
      interval: null,
    },
  }),
  methods: {
    close() {
      this.dialog = false;
      this.payment = null;
      this.error = false;
    },
    async open() {
      this.dialog = true;
      this.error = false;
      try {
        this.loading = true;
        const secrets = await this.getSecrets();
        this.loading = false;
        await this.startProgressBar(5000);
        window.location.href = secrets.init_point;
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.error = error.message;
      }
    },
    startProgressBar(ms) {
      const resolution = 50;
      return new Promise((resolve) => {
        this.progressBar.time = this.progressBar.initial = ms;
        this.progressBar.interval = setInterval(() => {
          if (this.progressBar.time > 0) {
            this.progressBar.time -= resolution;
          } else {
            clearInterval(this.progressBar.interval);
            resolve();
          }
        }, resolution);
      });
    },
    async getSecrets() {
      const response = await TICKET.pay(this.ticketId);
      this.payment = response.payment;
      return {
        init_point: response.init_point,
      };
    },
  },
  filters: {
    currency(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  async mounted() {
    this.$parent.$on("pay", this.open);
  },
  beforeDestroy() {
    this.progressBar.time = 0;
    clearInterval(this.progressBar.interval);
  },
  props: {
    ticketId: {
      type: String,
      required: true,
    },
  },
};
</script>
