<template>
  <v-dialog v-model="dialog" max-width="510px" :persistent="loading">
    <v-card :loading="loading">
      <v-card-title> Importar foto do Whatsapp </v-card-title>
      <v-card-text>
        <template v-if="!success">
          <v-alert
            :type="error ? 'error' : 'info'"
            border="left"
            text
            class="mb-2"
          >
            <p class="mb-0" v-if="!error">
              Para importar sua foto do Whatsapp, ela deve estar visível para
              todos ou o contato da Atletic.Me deve estar salvo em seu celular.
            </p>
            <p class="mb-0" v-else>
              Ocorreu um erro ao importar sua foto do Whatsapp. Verifique se ela
              está visível para todos ou se o contato da Atletic.Me está salvo em
              seu celular.
            </p>
            <v-btn block small color="primary" @click="saveContact">
              Salvar contato
            </v-btn>
          </v-alert>
        </template>
        <v-alert v-else type="success" border="left" text class="mb-0">
          <p class="mb-0">Sua foto foi importada com sucesso!</p>
        </v-alert>
      </v-card-text>
      <v-card-actions class="px-6 pb-2">
        <div class="w-full text-center">
          <template v-if="!success">
            <v-btn
              block
              color="success"
              :loading="loading"
              @click="importPhoto"
            >
              Importar Foto
            </v-btn>
            <small>
              Ao clicar em <code>IMPORTAR FOTO</code> você concorda que a
              <i>Atletic.Me</i> pode acessar sua foto do Whatsapp.
            </small>
          </template>
          <v-btn :disabled="loading" block text @click="dialog = false">
            Fechar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const { VUE_APP_TICKETME_WHATSAPP } = process.env;
import ACCOUNT from "@/services/account";
import { mapActions } from "vuex";

export default {
  data: () => ({
    dialog: false,
    error: false,
    success: false,
    loading: false,
  }),
  methods: {
    ...mapActions("auth", ["setUserPhoto"]),
    async importPhoto() {
      try {
        this.loading = true;
        const response = await ACCOUNT.importPhotoWhatsapp();
        this.setUserPhoto(response.photo);
        this.success = true;
      } catch (e) {
        console.log(e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    saveContact() {
      window.open(`//wa.me/${VUE_APP_TICKETME_WHATSAPP}`, "_blank");
    },
    open() {
      this.dialog = true;
      this.loading = false;
      this.error = false;
      this.success = false;
    },
  },
  mounted() {
    this.$parent.$on("import-whatsapp-photo", this.open);
  },
};
</script>

<style></style>
